import React from 'react';
import { navigate } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Header from '../components/Header/Header';

import AtAGlance from '../components/shared/AtAGlance/AtAGlance';
import BookADemo from '../components/shared/BookADemo/BookADemo';
import Customers from '../components/shared/Customers/Customers';
import IlustrationIcon from '../icons/ilustration_07.inline.svg';

import useScroll from '../utils/useScroll';

const navigateToComponent = idComponent =>
  navigate(`/our-product/#${idComponent}`);

const PageNotFound = () => {
  const [scrollToBookADemo, bookADemoComponent] = useScroll();

  const scrollToElements = {
    'crafted-for-you': () => navigateToComponent('crafted-for-you'),
    'behavioral-security': () => navigateToComponent('behavioral-security'),
    'security-posture': () => navigateToComponent('security-posture'),
    'latest-threats': () => navigateToComponent('latest-threats'),
    'security-to-others': () => navigateToComponent('security-to-others'),
    'security-organization': () => navigateToComponent('security-organization'),
  };

  return (
    <Layout>
      <Seo
        title="Edwin - Cybersecurity designed for humans"
        description="Edwin is a behavioral cybersecurity platform that helps organizations reduce security risks, comply with policies, and shape their security culture through small, ongoing, and verifiable security missions."
      />
      <Header demoButtonNavigation="/book-demo" />
      <BookADemo refToElement={bookADemoComponent} />
      <AtAGlance
        scrollToBookADemo={scrollToBookADemo}
        scrollToElements={scrollToElements}
        IlustrationIcon={IlustrationIcon}
      />
      <Customers />
    </Layout>
  );
};

export default PageNotFound;
